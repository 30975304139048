import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class EcommerceShippingRegionService {

      #api;
      constructor() {
            this.#api = API_URL + 'user/website';
      }

      paginate(domainName) {
            let url = `${this.#api}/${domainName}/shipping-region`;
            return apiService.get(url)
      }

      create(domainName, data) {
            let url = `${this.#api}/${domainName}/shipping-region`;
            return apiService.post(url, data)
      }

      show(domainName, id) {
            let url = `${this.#api}/${domainName}/shipping-region/${id}`;
            return apiService.get(url)
      }

      update(domainName, id, data) {
            let url = `${this.#api}/${domainName}/shipping-region/${id}`;
            return apiService.post(url, data)
      }

      delete(domainName, id) {
            let url = `${this.#api}/${domainName}/shipping-region/${id}`;
            return apiService.delete(url)
      }

      getByWebsite(domainName){
            let url = `${this.#api}/${domainName}/all/shipping-region`;
            return apiService.get(url)
      }
}
