<template>
  <section id="flat_rate_method">
    <v-app>
      <v-row>
        <v-col md-6 class="">
          <h3>Flat Rate</h3>
          <small>Manage Payment gateways on your website checkout</small>
        </v-col>
        <v-col md-6 class="text-right">
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" class="ml-5">
                  <v-col cols="12">
                    <h4 class="card-title">Shipping name at checkout</h4>
                    <p>Enter the name under which customers see this shipping method at checkout.</p>
                    <v-text-field v-model="method.title" outlined dense label="Standard shipping"></v-text-field>
                    <span class="text-danger" v-if="$v.method.title.$error">
                      <strong>Title is required</strong>
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <h4 class="card-title">Shipping rates</h4>
                    <p>Please enter the shipping cost as either a flat amount or flat percentage of the total price.</p>
                    <v-row>
                      <v-col cols="6">
                        <v-select
                          :items="types"
                          outlined
                          dense
                          item-text="title"
                          item-value="value"
                          v-model="method.price_based_on"
                        ></v-select>
                      </v-col>
                      <v-col cols="6" v-for="(p, i) of method.prices" :key="i">
                        <v-text-field
                          outlined
                          dense
                          v-model="p.price"
                          :prefix="currency_symbol"
                          label="0.00"
                        ></v-text-field>
                        <span class="text-danger" v-if="$v.method.prices.$each[i].price.$error">
                          <strong>Price is required</strong>
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <h4 class="card-title">Description for customers</h4>
                    <p>What should customers know when choosing this shipping method for their order? Specify the time it usually takes for orders to arrive, the way in which the shipment will be handed over to the buyer, or anything else you feel is important for customers to know about shipping option. This information will be displayed at checkout.</p>
                    <v-textarea v-model="method.description" outlined dense></v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <h4 class="card-title">Minimum order subtotal</h4>
                    <p>Specify the order subtotal before discounts. This delivery method won’t be available at checkout for orders below that amount.</p>
                    <v-text-field
                      v-model="method.minimum_order_subtotal"
                      outlined
                      :prefix="currency_symbol"
                      dense
                      label="0.00"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <h4 class="card-title">Shipping region</h4>
                    <p>Specify the destination that you ship to with this method. If shipping rates should differ depending on the customer's address, create a new shipping method and assign it to the corresponding destination zone.</p>
                    <v-radio-group v-model="method.shipping_region">
                      <v-radio
                        class="radio-item"
                        v-for="(option, n) in options"
                        :key="n"
                        :label="option.title"
                        :value="option.value"
                        @change="onChangeRadio(option.value)"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-row>
                    <v-col cols="12" v-if="advance_setting">
                      <p>Set delivery zone by country, ZIP/postal code range, or state if you deliver to specific regions.</p>
                      <v-row>
                        <v-col cols="8">
                          <v-select
                            v-model="method.ecommerce_shipping_region_id"
                            outlined
                            dense
                            :items="zones"
                            item-text="title"
                            item-value="id"
                          ></v-select>
                          <span
                            class="text-danger"
                            v-if="$v.method.ecommerce_shipping_region_id.$error"
                          >
                            <strong>Region is required</strong>
                          </span>
                        </v-col>
                        <v-col cols="4">
                          <router-link :to="{name: 'shipping-zone'}">Manage Zone</router-link>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn outlined dense @click="createOrUpdate" :loading="loading">Save</v-btn>
                  </v-col>
                </v-col>
                <v-col cols="4">
                  <!-- <pre>{{method}}</pre> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-app>
  </section>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import EcommerceShippingMethodService from "@/services/store/shipping/method/EcommerceShippingMethodService";
import EcommerceShippingRegionService from "@/services/store/shipping/EcommerceShippingRegionService";

const methodService = new EcommerceShippingMethodService();
const regionService = new EcommerceShippingRegionService();

export default {
  name: "flat-rate",
  validations() {
    return {
      method: this.rules
    };
  },
  data() {
    return {
      methodId: null,
      loading: false,
      optionValue: null,
      advance_setting: false,
      options: [
        { title: "All Destination", value: "all" },
        { title: "Advance Setting", value: "customized" }
      ],
      zones: [],
      shippingMethodType: null,
      domainname: null,
      loading: false,
      method: {
        type: null,
        title: null,
        is_active: 1,
        shipping_region: "all",
        carrier_name:null,
        minimum_order_subtotal: null,
        ecommerce_shipping_region_id: null,
        description: null,
        price_based_on: "amount",
        prices: [{ price: 0.0 }]
      },
      types: [
        { title: "Amount", value: "amount" },
        { title: "Percentage", value: "percentage" }
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currency_symbol() {
      return this.currentUser.store_config.currency_symbol;
    },
  //   carrier_name(){
  //     console.log(this.$route.params)
  //     // this.method.carrier_name = this.$router.params.carrier_name;
  //     // return this.$route.params.carrier_name
  //   },
  //   // watch: {
  //   // $route() {
  //   //   this.filter.status = this.$route.params.status;
  //   //   this.filterProdcut();
  //   // }
  // // },
    rules() {
      let method = {
        title: { required },
        prices: {
          required,
          $each: {
            price: { required }
          }
        }
      };
      if (this.method.shipping_region === "customized")
        method.ecommerce_shipping_region_id = { required };

      return method;
    }
  },
  mounted() {
    this.domainname = this.$route.params.domainname;
    this.shippingMethodType = "flat";
    this.method.type = this.shippingMethodType;
     this.setCarrierName();
    if (
      this.$route.params.id != null &&
      this.$route.params.id != undefined &&
      this.$route.params.id != ""
    ) {
      this.methodId = this.$route.params.id;
      this.getMethod(this.methodId);
    }
    this.getZones();
  },
  methods: {
    setCarrierName(){
      this.method.carrier_name=  this.$route.params.carrier_name;
    },
    getMethod(id) {
      methodService.show(this.domainname, id).then(response => {
        this.method = response.data.method;
        if (this.method.shipping_region === "customized") {
          this.advance_setting = true;
        }
        this.edit = true;
      });
    },
    getZones() {
      regionService.getByWebsite(this.domainname).then(response => {
        this.zones = response.data.regions;
      });
    },
    onChangeRadio(value) {
      if (value === "customized") {
        this.advance_setting = true;
      } else {
        this.advance_setting = false;
        this.method.ecommerce_shipping_region_id = null;
      }
    },

    createOrUpdate() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.method.$error) {
        setTimeout(() => {
          this.$v.method.$reset();
          this.loading = false;
        }, 3000);
      } else {
        if (this.edit) {
          this.update();
        } else {
          this.save();
        }
      }
    },
    save() {
      methodService
        .create(this.domainname, this.method)
        .then(response => {
          this.reset();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      methodService
        .update(this.domainname, this.method.id, this.method)
        .then(response => {
          this.reset();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$v.method.$reset();
      this.$snotify.success("Updated Successfully");
      this.$router.push({ name: "shipping" });
    }
  }
};
</script>
<style scoped>
#flat_rate_method .card-title {
  font-size: 25px !important;
  font-weight: 600 !important;
}
#flat_rate_method p {
  font-size: 14px !important;
  font-weight: 500 !important;
}

#flat_rate_method .radio-item {
  font-size: 16px !important;
  font-weight: 500 !important;
}
</style>
