import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;

export default class EcommerceShippingMethodPriceService {
      #api;
      constructor() {
            this.#api = API_URL + 'user/website';
      }
      paginate(domainname) {
            let url = `${this.#api}/${domainname}/shipping-method`;
            return apiService.get(url)
      }

      create(domainname, data) {
            let url = `${this.#api}/${domainname}/shipping-method`;
            return apiService.post(url, data)
      }

      show(domainname,id) {
            let url = `${this.#api}/${domainname}/shipping-method/${id}`;
            return apiService.get(url)
      }

      update(domainname,id, data) {
            let url = `${this.#api}/${domainname}/shipping-method/${id}`;
            return apiService.put(url, data)
      }

      delete(domainname,id) {
            let url = `${this.#api}/${domainname}/shipping-method/${id}`;
            return apiService.delete(url)
      }

      toggleStatus(domainname,id) {
            let url = `${this.#api}/${domainname}/shipping-method/${id}/toggle`;
            return apiService.get(url)
      }


}
